import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const ReactPlayer = loadable(() => import('react-player/wistia'))

const VideoPlayer = ({ data, variant, light, className }) => {
    const [videoThumbnail, setVideoThumbnail] = useState('')
    const videoUrlSlice = data.slice(-10)
    const thumbnailSize = variant === 'square' ? '630x630' : variant === 'portrait' ? '354x630' : '630x354'

    useEffect(() => {
        fetch(`https://api.wistia.com/v1/medias/${videoUrlSlice}.json`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${process.env.GATSBY_WISTIA_ACCESS_TOKEN}`
            }
        })
            .then((response) => response.json())
            .then((resultData) => {
                const thumbnailUrl = resultData.thumbnail?.url
                const thumbnail = `${thumbnailUrl.substring(-7, thumbnailUrl.length - 7)}${thumbnailSize}`
                setVideoThumbnail(thumbnail)
            })
    }, [videoUrlSlice, thumbnailSize])

    const classes = [
        'c-video-player',
        ...(variant ? [`c-video-player--${variant}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <div className={concatenatedClasses} style={{ backgroundImage: `url(${videoThumbnail})` }}>
            <ReactPlayer className={'c-video-player__player'} url={data} width={'100%'} height={'100%'} light={light} />
        </div>
    )
}

VideoPlayer.propTypes = {
    /**
     * Data
     */
    data: PropTypes.string.isRequired,
    /**
     * Specify a different variant
     */
    variant: PropTypes.oneOf(['standard', 'square', 'portrait']),
    /**
     * Whether the video player is a 'light' version (only loads when user interacts with it)
     */
    light: PropTypes.bool,
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

VideoPlayer.defaultProps = {
    data: 'https://protect-line-marketing.wistia.com/medias/sce6ad8azy',
    variant: 'standard',
    light: true
}

export default VideoPlayer
